import React from 'react'
import Link from 'gatsby-link'

import classes from '../Content.module.css'
import { CallIcon, CalendarIcon, CheckIcon } from '../../icons'

const iconColor = "#4B53BC" // primary color

export default () => (
    <div className={classes.HowItWorksContainer}>
        <div  style={{maxWidth: 1170, padding: '40px 20px', margin: 'auto'}}>
            <div style={{textAlign: 'center'}}>
                <h2 style={{margin: '0 0 10px 0'}}>Comment nous fonctionnons</h2>
                <h5>Nos prestations peuvent être remboursées par votre assurance maladie de base</h5>
            </div>
            <div className={classes.HowItWorks}>
                <div className={classes.HowItWorksItem}>
                    <IconWrapper>
                        <CallIcon fill={iconColor}/>
                    </IconWrapper>
                    <div style={{textAlign: 'center'}}>
                        Appelez-nous au <a style={{color: "#fff", textDecoration: 'underline'}} href="tel:+41582010611"><strong>058 201 06 11</strong></a> (ou <Link style={{color: "#fff", textDecoration: 'underline'}} to="/reservation"><strong>réserver une consultation</strong></Link> sur notre site web).
                    </div>
                </div>
                <div className={classes.HowItWorksItem}>
                    <IconWrapper>
                        <CalendarIcon fill={iconColor}/>
                    </IconWrapper>
                    <div style={{textAlign: 'center'}}>
                        Selon de vos besoins, on fixera un rendez-vous avec un thérapeute adapté spécialement pour vous.
                    </div>
                </div>
                <div className={classes.HowItWorksItem}>
                    <IconWrapper>
                        <CheckIcon fill={iconColor}/>
                    </IconWrapper>
                    <div style={{textAlign: 'center'}}>
                        Rencontrez votre spécialiste et entrez sur le chemin de la guérison. Des questions? Laissez nous savoir comment on peut vous assister.
                    </div>
                </div>
            </div>
        </div>


    </div>
)

const IconWrapper = ({ children }) => {
    return(
        <div style={{width: 48, height: 48, borderRadius: 100, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem'}}>
            {children}
        </div>
    )
}
