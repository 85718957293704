import React from 'react'
import Link from 'gatsby-link'
import CallIcon from '../../images/call-icon.svg'
import FormIcon from '../../images/fill-form-icon.svg'
import WhatsApp from '../../images/whatapp-icon.png'

import classes from './MobileActions.module.css'

export default () => {
    return (
        <div className={classes.ActionWrapper}>
            <CallAction/>
            <FillFormAction/>
            
            {/* <WhatsAppAction/> */}
        </div>
    )
}

const CallAction = () => (
    <div className={classes.IconShadow} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#4cae4c',
        height: 58,
        width: 58,
        padding: '18px 12px 8px',
        position: 'fixed',
        bottom: 20,
        right: 'calc(20px)',
        // right: 'calc(20px + 58px + 10px)',
        borderRadius: '50%'
    }} onClick={() => {
        if(window.gtag) {
            window.gtag('event', 'initCall', {
                'event_category': "Call",
                'event_label' : 'Website'
            });
        }
    }}>
        <a href="tel:+41582010611">
            <img src={CallIcon} style={{width: 34, margin: 0}}/>
        </a>
    </div>
)

const FillFormAction = () => (
    <div className={classes.IconShadow} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FF7F00',
        height: 58,
        width: 58,
        padding: '18px 12px 8px',
        position: 'fixed',
        bottom: 20,
        right: 'calc(20px + 58px + 10px + 10px)',
        
        // right: 'calc(20px + 58px + 10px + 58px + 10px)',
        borderRadius: '50%'
    }}>
        <Link to="/reservation">
            <img src={FormIcon} style={{width: 34, margin: 0}}/>
        </Link>
    </div>
)

const WhatsAppAction = () => (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 58,
        width: 58,
        position: 'fixed',
        bottom: 20,
        right: '20px',
        paddingTop: 4
    }}>
        <a href="https://wa.me/41582010611">
            <img src={WhatsApp} style={{width: '100%', margin: 0}}/>
        </a>
    </div>
);
