import React from 'react'
import ReactPlayer from 'react-player'
import Button from '../Button/Button'
import LeadForm from '../Forms/LeadForm'
import { navigateTo } from 'gatsby-link'


import Mozaique from './Mozaique'
import MozaiqueStyles from './Mozaique.module.css'

const HeroSection = (props) => {
    const { location } = props
    return (
        <Mozaique {...props}>
            <div  className={MozaiqueStyles.Wrapper}>
                <div className={MozaiqueStyles.InnerContentLeft}>
                    {location.pathname
                        ? <div>
                            {location.pathname === '/neuchatel' || location.pathname === '/neuchatel/' || location.pathname === '/lausanne' || location.pathname === '/lausanne/'
                                ? <div>
                                    <h1 className={MozaiqueStyles.H1Ads}>Psychologues et Psychiatres à {props.city}</h1>
                                    <div className={MozaiqueStyles.SubH1}>Découvrez comment nos spécialistes peuvent vous aider</div>
                                </div>
                            : <div>
                                    <h1 className={MozaiqueStyles.H1}>Bien se sentir pour mieux vivre</h1>
                                </div>}
                        </div>
                        : null
                    }

                    <div className={MozaiqueStyles.VideoWrapper}>
                        <ReactPlayer
                            url='https://good-biyong.wistia.com/medias/yy5oykwkyr'
                            config={{
                                wistia: {
                                    options: {
                                        playerColor: "#0071BC"
                                    }
                                }
                            }}
                            width="100%"
                            height="100%"
                        />
                    </div>

                    <div style={{fontSize: '0.8rem', padding: '10px 0 0'}} className={MozaiqueStyles.VideoFooterText}>
                        <div style={{
                            fontSize: '1rem',
                            backgroundColor: "#04040487",
                            padding: '0 10px',
                            fontWeight: 500,
                            display: 'inline-block',
                        }}>*Nos prestations peuvent être prises en charge par l’assurance maladie de base</div>
                        <div style={{
                            backgroundColor: "#04040487",
                            padding: '0 10px',
                            display: 'inline-block',
                        }}>Les places sont limitées due à la forte demande des services de consultation. </div>
                    </div>
                </div>

                <div style={{ position: props.position, top: props.top}} className={MozaiqueStyles.FullLeadForm}>
                    <LeadForm {...props}/>
                </div>
                <MobileHeroCallToAction />

            </div>
        </Mozaique>
    )
}

const MobileHeroCallToAction =() => (
    <div style={{textAlign: 'center', padding: 20}} className={MozaiqueStyles.MobileCallToAction}>
        <Button color="primary" text="Réservez une Consulation en Ligne" styles={buttonStyles} clicked={() => navigateTo('/reservation')}/>
        <div style={{padding: '20px 0'}}>
            <h4 style={{color: "#fff", marginBottom: 10}}>Ou appelez-nous maintenant</h4>

            <div onClick={() => {
                if(window.gtag) {
                    window.gtag('event', 'initCall', {
                        'event_category': "Call",
                        'event_label' : 'Website'
                    });
                }
            }}><a href="tel:+41582010611">
                <Button color="secondary" text="058 201 06 11" styles={buttonStyles} />
            </a>
            </div>
        </div>
        <div style={{fontSize: '0.7rem', padding: '10px 0 0', color:'#fff'}}>
            <div style={{
                fontSize: '0.9rem',
                backgroundColor: "#04040487",
                padding: '4px 10px 0',
                fontWeight: 500,

            }}>Nos prestations peuvent être prises en charge par l’assurance maladie de base*</div>
            <div style={{
                backgroundColor: "#04040487",
                padding: '0 10px 4px',
            }}>Les places sont limitées due à la forte demande des services de consultation. </div>
        </div>
    </div>
)

const buttonStyles = {
    padding: 10,
    minWidth: 280
}

export default HeroSection
